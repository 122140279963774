.homeHandleStyle {
  /* padding: 1rem;
  overflow-y: scroll;
  /* max-height: 750px;
  min-height: 545px; */
  /* overflow-x: hidden;
  height: 100%; */
  margin: auto;
  width: 92%;
}

.scrolltopbar {
  padding-top: 60px;
}

.monitor-container {
  margin-left: 70px;
  margin-right: 10px;
  margin-top: 70px;
  background-color: var(--Light-Surface-color-Grey-100, #1c1c62);
}

.active-subtab-link {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-bottom: 2.5px solid #8c57ff;
  /* padding: 14px 14px; */
  /* width: 180px; */
}

.inactive-subtab-link {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* background: "var(--Light-Surface-color-Grey-100, #FBFBFE)"; */
  /* border-radius: 8px; */
  border-bottom: 2.5px solid #a5a5a7;
}

.menu-item-text {
  color: var(--Light-Surface-color-Grey-900, #272729);
  text-align: center;
  font-family: "Roboto Flex" !important;
  font-size: 18px;
  cursor: pointer;
  font-weight: 600;
  line-height: 120%;
  padding: 14px;
  font-style: normal;
  letter-spacing: 0.5px;
}

.siteErrorName {
  font-family: "Roboto Flex" !important;
  font-size: 16px;
  color: #ff5d5d;
}

.siteInfo {
  width: 1088px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.105),
    0 6px 20px 0 rgba(0, 0, 0, 0.105);
  background-color: var(--Light-Surface-color-Grey-50, #fff);
  padding: 30px;
  border-radius: 4px;
}

.tab-view {
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.105), 0 6px 20px 0 rgba(0, 0, 0, 0.105); */
  display: flex;
  border-radius: 5px;
  background-color: var(--Light-Surface-color-Grey-100, #fbfbfe);
  width: fit-content;
}

.siteView {
  padding: 32px;
  margin-left: 40px;
  margin-top: 60px;
}

.siteViewMember {
  padding: 32px;
  margin-left: 40px;
}

.siteName {
  color: var(--Light-Surface-color-Grey-900, #272729);
  /* Desktop/Heading 5 */
  font-family: "Roboto Flex";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  /* 21.6px */
  margin-bottom: 25px;
}

.siteNameView {
  color: var(--Light-Surface-color-Grey-900, #272729);
  /* Desktop/Subheading 4 */
  font-family: "Roboto Flex";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  /* 19.2px */
  margin-top: 8px;
}

.siteTab {
  display: flex;
}

.cancelClick {
  display: flex;
  width: 120px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: var(--Light-Surface-color-Grey-500, #a5a5a7);
  color: var(--Light-Surface-color-Grey-100, #fbfbfe);
  border: var(--Light-Surface-color-Grey-100, #fbfbfe);
  font-family: "Roboto Flex";
  font-size: 13px;
  font-style: normal;
  font-weight: 800;
  line-height: 120%;
  letter-spacing: 0.26px;
  text-transform: uppercase;
}

.submitClick {
  display: flex;
  width: 120px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: var(--Light-Primary-Green-600, #25dba7);
  color: var(--Light-Surface-color-Grey-100, #fbfbfe);
  border: var(--Light-Surface-color-Grey-100, #fbfbfe);
  font-family: "Roboto Flex";
  font-size: 13px;
  font-style: normal;
  font-weight: 800;
  line-height: 120%;
  /* 15.6px */
  letter-spacing: 0.26px;
  text-transform: uppercase;
}

.siteInput {
  display: flex;
  width: 100%;
  height: 32px;
  padding: 0px 16px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid var(--Light-Surface-color-Grey-400, #c3c3c5);
  background: var(--Light-Surface-color-Grey-100, #fbfbfe);
  margin-top: 5px;
}

.siteMemberView {
  width: 1088px;
  height: 280px;
  flex-shrink: 0;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.105),
    0 6px 20px 0 rgba(0, 0, 0, 0.105);
  background-color: var(--Light-Surface-color-Grey-50, #fff);
  border-radius: 4px;
}

.lastSeen {
  font-family: "Roboto Flex", sans-serif;
  background: #25dba7;
  width: 233px;
  height: 40px;
  border-radius: 8px;
  color: var(--Light-Surface-color-Grey-50, #fff);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: none;
  float: right;
}

.lastSeen.spinning .svg-inline--fa {
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.success-name-dialog {
  background-color: #84ce66;
  margin-right: 20px;
  color: #272729;
}

.error-name-dialog {
  background-color: #ff5d5d;
  margin-right: 20px;
  color: #272729;
}

.success-dialog {
  background-color: #84ce66;
  color: #272729;
}

.error-dialog {
  background-color: #ff5d5d;
  color: #272729;
}

.notification-alert {
  top: 530px;
  left: 1177px;
  width: 2300px;
  height: 131px;
  font-family: "Roboto Flex", sans-serif;
  float: right;
  z-index: 100000;
}

.filter {
  display: block;
}

.sticky-button {
  display: none;
  position: fixed;
  bottom: 60px;
  left: 68%;
  transform: translateX(-50%);
  border: none;
  border-radius: 50px;
  padding: 10px 0px;
  width: 55%;
  z-index: 1000;
  justify-content: space-between;
}

.filter-btn {
  border: 1px solid gray;
  border-radius: 5px;
  background-color: white;
  padding: 8px 18px;
  cursor: pointer;
}

.btn-color {
  color: grey;
  font-size: 22px;
}

.noEquipment {
  color: var(--Light-Surface-color-Grey-900, #272729);
  font-family: "Roboto Flex", sans-serif;
  font-size: 18px;
  font-weight: 500;
  margin-left: 34px;
  margin-top: 45px;
  margin-bottom: 20px;
}
.lastseen-btn-color {
  font-size: 22px;
  color: white;
}

.popup {
  position: fixed;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  background: white;
  z-index: 9999;
  padding: 12px;
  width: 100%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.close-popup {
  /* position: absolute; */
  float: right;
  font-family: "Roboto Flex", sans-serif;
  background: transparent;
  border: none;
  font-size: 16px;
  font-weight: 200;
  cursor: pointer;
}

.dropdownmobileExportStyle {
  display: flex;
  width: 100%;
  height: 56px;
  padding: 0px 16px;
  align-self: center;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  border: 1px solid var(--scroll-bar-color);
  background: var(--Light-Surface-color-Grey-50, #fff);
  box-shadow: 0px 0px 4px 0px var(--Light-Surface-color-Grey-50, #fff);
  margin-top: 8px;
  color: var(--Light-Surface-color-Grey-900, #272729);
  text-align: justify;
}

.dropdownExportStyle {
  display: flex;
  width: 200px;
  height: 56px;
  padding: 0px 16px;
  align-self: center;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  border: 1px solid var(--scroll-bar-color);
  background: var(--Light-Surface-color-Grey-50, #fff);
  box-shadow: 0px 0px 4px 0px var(--Light-Surface-color-Grey-50, #fff);
  margin-top: 8px;
  color: var(--Light-Surface-color-Grey-900, #272729);
  text-align: justify;
}

.optionMobileValue {
  padding: 0.65rem 1.25rem !important;
  height: 40px;
  width: 100px;
}

.dropdownMobileOptionStyle {
  width: 100%;
}

.lastseen-txt {
  display: none;
}

.MobileView,
.mobileViewMember {
  display: none;
}

.left-navigation {
  margin-left: 70px;
  margin-right: 5px;
  margin-top: 70px;
  border-radius: 8px;
}

.desktopbg {
  background: var(--Light-Surface-color-Grey-100, #fbfbfe);
  padding-top: 25px;
}

.tab-view {
  display: flex;
}

.site-name-heading {
  color: var(--Light-Surface-color-Grey-900, #272729);
  font-family: "Roboto Flex", sans-serif;
  font-size: 32px;
  font-weight: 600;
  margin-left: 34px;
  margin-top: 25px;
  margin-bottom: 20px;
}

.dropdown-view {
  display: block;
}

@media only screen and (max-width: 768px) {
  .scroll-container {
    height: calc(100vh - 100px);
    overflow-y: scroll;
  }

  .desktopbg {
    padding-top: 0px;
  }

  .mobile-container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }

  .dropdownSiteModeOptionStyle {
    width: 100% !important;
    margin: auto;
  }

  .left-navigation {
    margin-left: 0px;
    margin-right: 0px;
    background: var(--Light-Surface-color-Grey-100, #fbfbfe);
    margin-bottom: 124px;
  }

  .tab-view {
    display: none;
  }

  .scroll-top-bar {
    padding-top: 60px;
  }

  .homeHandleStyle {
    margin: 10px;
    padding-bottom: 60px;
  }

  .lastseen-txt {
    display: block;
    padding-left: 10px;
    font-family: "Roboto Flex", sans-serif;
    font-weight: 200;
  }

  .mobile-margin {
    margin-top: 8px;
  }

  .sticky-button {
    display: block;
  }

  .siteViewMember,
  .siteView {
    display: none;
  }

  .MobileView {
    padding: 10px;
    display: block;
  }

  .mobileViewMember {
    display: block;
  }

  .siteName {
    margin-bottom: 5px;
  }

  .moblieInfo {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.105),
      0 6px 20px 0 rgba(0, 0, 0, 0.105);
    background-color: var(--Light-Surface-color-Grey-50, #fff);
    padding: 10px;
    border-radius: 8px;
  }

  .dropdown-view {
    display: none;
  }

  .site-name-heading {
    font-size: 28px;
    margin-left: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  @media only screen and (max-width: 768px) {
    .batteryChargeView {
      height: 220px;
    }
  }
}
