.login-container {
  margin-top: 195px;
}

.bg-login {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
}

.loginBlock {
  display: flex;
  width: 1920px;
  padding-bottom: 491px;
  flex-direction: column;
  align-items: center;
  gap: 110px;
}

.subhead {
  color: var(--Light-Surface-color-Grey-500, #a5a5a7);
  font-size: 12px;
  line-height: normal;
  font-family: "Roboto Flex", sans-serif;
  font-optical-sizing: auto;
  font-weight: 120;
  font-style: normal;
}

.login-btn-watt {
  width: 195px;
  height: 38px;
  flex-shrink: 0;
  border-radius: 5px;
  background: var(--Light-Primary-Green-500, #4ce1b6);
  text-decoration: none;
}

.register-btn-watt {
  width: 195px;
  height: 38px;
  flex-shrink: 0;
  border-radius: 5px;
  border: 1px solid var(--Light-Primary-Green-500, #4ce1b6);
  color: var(--Light-Primary-Green-500, #4ce1b6);
  text-decoration: none;
  margin-left: 12px;
}

.signIn-Watt {
  color: var(--Light-Surface-color-Grey-50, #fff);
  text-align: center;
  font-size: 13px;
  font-style: normal;
  line-height: normal;
  /* width: 67.993px; */
  height: auto;
  margin: 0 auto;
  padding: 10px;
  position: relative;
  text-transform: uppercase;
  font-family: "Roboto Flex", sans-serif;
  font-optical-sizing: auto;
  font-weight: 800;
  font-style: normal;
}

.input-wattMastro {
  width: 100%;
  padding: 5px 10px;
  font-size: 16px;
  height: 32px;
  -webkit-transition: border 0.3s;
  transition: border 0.3s;
  background: transparent;
  border: 1px solid #f2f4f7;
  color: var(--Light-Surface-color-Grey-500, #a5a5a7);
  font-family: "Roboto Flex", sans-serif;
  font-optical-sizing: auto;
  font-weight: 450;
  line-height: 120%;
  font-style: normal;
}

.atIcon-WattMaestro {
  padding: 6px;
  height: 32px;
  background: #f2f4f7;
  border: 1px solid #f2f4f7;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 35px;
  color: var(--Light-Surface-color-Grey-500, #a5a5a7);
}

.register-Watt {
  line-height: normal;
  height: auto;
  margin: 0 auto;
  padding: 10px;
  position: relative;
  color: var(--Light-Primary-Green-500, #4ce1b6);
  text-align: center;
  font-size: 13px;
  letter-spacing: 0.26px;
  text-transform: uppercase;
  font-family: "Roboto Flex", sans-serif;
  font-optical-sizing: auto;
  font-weight: 800;
  font-style: normal;
}

.wattTitle {
  color: var(--Light-Primary-Green-500, #4ce1b6);
  font-family: Montserrat;
  font-size: 24px;
  text-decoration: none;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.48px;
  margin-left: 30px;
}

@media only screen and (max-width: 540px) {
  .login-btn-watt {
    width: 120px;
    height: 40px;
    flex-shrink: 0;
    border-radius: 5px;
    background: var(--Light-Primary-Green-500, #4ce1b6);
    text-decoration: none;
  }

  .register-btn-watt {
    width: 164px;
    height: 40px;
    flex-shrink: 0;
    border-radius: 5px;
    border: 1px solid var(--Light-Primary-Green-500, #4ce1b6);
    color: var(--Light-Primary-Green-500, #4ce1b6);
    text-decoration: none;
    margin-left: 20px;
  }
}

.login-btn {
  display: inline;
  text-align: center;
  text-decoration: none;
  font: normal normal 600 16px/15px Montserrat;
  letter-spacing: 1.6px;
  color: #ffffff;
  opacity: 1;
  background-color: #003dff;
  border-radius: 4px;
  padding: 7px 18px;
  border-color: #003dff;
}

.login-btn:hover {
  color: #ffffff;
  text-decoration: none;
}

.pass-reset-btn {
  text-align: center;
  text-decoration: underline;
  font: normal normal normal 12px/18px Montserrat;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.pass-reset-btn:hover {
  color: #ffffff;
  text-decoration: none;
}

.bace-logo {
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  margin-bottom: 30px;
  width: 70%;
}

.bg-login-container {
  width: 600px;
  height: 300px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 3px 6px 12px #000000a3;
  border-radius: 2px;
  opacity: 1;
}

.loginImgDiv {
  background-color: var(--Light-Surface-color-Grey-50, #fff);
  filter: drop-shadow(0px 2px 7px rgba(0, 0, 0, 0.07));
  padding: 14px;
  width: 1920px;
  height: 60px;
  justify-content: center;
  align-items: center;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  margin-bottom: 20px;
}

.loginImg {
  width: 10%;
  margin-left: 12px;
}

.welcomeBoard {
  font-size: 24px;
  text-align: center;
  line-height: 72px;
  font-weight: 400;
}

.successBoard {
  height: 105px;
}

.loginView {
  text-align: center;
}

.login-register {
  display: inline;
  text-align: center;
  text-decoration: none;
  font: normal normal 600 16px/15px Montserrat;
  letter-spacing: 1.6px;
  background-color: #ffffff;
  opacity: 1;
  color: #003dff;
  border-radius: 4px;
  border-color: #003dff;
  padding: 7px 18px;
  border-style: solid;
  margin-left: 25px;
}

.link-register {
  text-decoration: auto;
}

.cancel-register {
  border-style: hidden;
}

.logo-watt {
  display: flex;
  justify-content: flex-start; /* Align items to the left */
  align-items: center; /* Vertically center the items */
  width: 100%;
  height: 100%; /* Optional: You can specify height if needed */
}

/* Logo icon container */
.logo-login-icon-container {
  display: flex;
  justify-content: center; /* Horizontally center the image */
  align-items: center; /* Vertically align the image */
  width: 35%;
}

/* Image styling for the WattMaestro logo */
.logo-login-icon-container img {
  width: 40%; /* Set width as 60% of the container */
  height: auto; /* Keep aspect ratio intact */
  max-width: 100%; /* Prevent overflow */
  float: left;
}

.logoImgDiv {
  background-color: var(--Light-Surface-color-Grey-50, #fff);
  filter: drop-shadow(0px 2px 7px rgba(0, 0, 0, 0.07));
  padding: 14px;
  width: 1920px;
  height: 60px;
  justify-content: center;
  align-items: center;
}
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  margin-bottom: 20px;
}

.wattTitleLogo {
  color: var(--Light-Primary-Green-500, #4ce1b6);
  font-family: Montserrat;
  font-size: 24px;
  text-decoration: none;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.48px;
  margin-left: 50px;
}
@media only screen and (max-width: 768px) {
  .logo-icon-container {
    width: 170px;
  }
  .logo-icon-container img {
    width: 100%; /* Set width as 60% of the container */
    height: auto; /* Keep aspect ratio intact */
    max-width: 100%; /* Prevent overflow */
    float: left;
  }
  .wattTitleLogo {
    color: var(--Light-Primary-Green-500, #4ce1b6);
    font-family: Montserrat;
    font-size: 24px;
    text-decoration: none;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.48px;
    margin-left: 20px;
  }
  .logo-login-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 75%;
    margin-top: 12px;
  }
}
