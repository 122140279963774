.btnHeader {
  background-color: lightgray;
}

.notification-icon-container {
  position: relative;
}

.notification-count {
  position: absolute;
  top: -5px; /* Adjust this to move the count higher or lower */
  right: -5px; /* Adjust this to move the count left or right */
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 12px;
}
.logo-watt {
  display: flex;
  justify-content: flex-start; /* Align items to the left */
  align-items: center; /* Vertically center the items */
  width: 100%;
  height: 100%; /* Optional: You can specify height if needed */
}

/* Logo icon container */
.logo-icon-container {
  display: flex;
  justify-content: center; /* Horizontally center the image */
  align-items: center; /* Vertically align the image */
}

/* Image styling for the WattMaestro logo */
.logo-icon-container img {
  width: 40%; /* Set width as 60% of the container */
  height: auto; /* Keep aspect ratio intact */
  max-width: 100%; /* Prevent overflow */
  float: left;
}

.logoLoginImgDiv {
  background-color: var(--Light-Surface-color-Grey-50, #fff);
  filter: drop-shadow(0px 2px 7px rgba(0, 0, 0, 0.07));
  padding-top: 10px;
  width: 1920px;
  height: 60px;
  justify-content: center;
  align-items: center;
}
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  margin-bottom: 20px;
}

.wattTitleLogo {
  color: var(--Light-Primary-Green-500, #4ce1b6);
  font-family: Montserrat;
  font-size: 24px;
  text-decoration: none;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.48px;
  margin-left: 50px;
  margin-bottom: 13px;
}

@media only screen and (max-width: 768px) {
  .logo-icon-container {
    width: 170px;
  }
  .logo-icon-container img {
    width: 100%; /* Set width as 60% of the container */
    height: auto; /* Keep aspect ratio intact */
    max-width: 100%; /* Prevent overflow */
    float: left;
  }
  .wattTitleLogo {
    color: var(--Light-Primary-Green-500, #4ce1b6);
    font-family: Montserrat;
    font-size: 24px;
    text-decoration: none;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.48px;
    margin-left: 20px;
    /* margin-bottom: 13px; */
  }
}
