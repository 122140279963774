.OnlineEquipment {
  color: var(--Light-System-color-Success-Light-green-500, #55b627);
  font-family: "Roboto Flex";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  margin-left: 15px;
}

.OfflineEquipment {
  color: var(--Light-System-color-Error-Red-500, #de0000);
  font-family: "Roboto Flex";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  margin-left: 15px;
}

.siteIcon {
  margin-left: 1px;
}

.siteIcon:hover {
  background: var(--Light-Surface-color-Grey-500, #fbfbfe);
}

.cancelSiteModeClick {
  display: flex;
  /* width: 120px; */
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: var(--Light-Surface-color-Grey-500, #a5a5a7);
  color: var(--Light-Surface-color-Grey-100, #fbfbfe);
  border: var(--Light-Surface-color-Grey-100, #fbfbfe);
  font-family: "Roboto Flex";
  font-size: 13px;
  font-style: normal;
  font-weight: 800;
  padding: 14px 30px;
  line-height: 120%;
  /* 15.6px */
  letter-spacing: 0.26px;
  text-transform: uppercase;
}

.submitSiteModeClick {
  display: flex;
  min-width: 120px;
  height: 40px;
  padding: 14px 30px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: var(--Light-Primary-Green-600, #25dba7);
  color: var(--Light-Surface-color-Grey-100, #fbfbfe);
  border: var(--Light-Surface-color-Grey-100, #fbfbfe);
  font-family: "Roboto Flex";
  font-size: 13px;
  font-style: normal;
  font-weight: 800;
  line-height: 120%;
  /* 15.6px */
  letter-spacing: 0.26px;
  text-transform: uppercase;
}

.remove-sitemode-click {
  display: flex;
  width: 120px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: var(--Light-Primary-Green-600, #FF5D5D);
  color: var(--Light-Surface-color-Grey-100, #fbfbfe);
  border: var(--Light-Surface-color-Grey-100, #fbfbfe);
  font-family: "Roboto Flex";
  font-size: 13px;
  font-style: normal;
  font-weight: 800;
  line-height: 120%;
  /* 15.6px */
  letter-spacing: 0.26px;
  text-transform: uppercase;
}

.alertDialog {
  width: 336px;
  position: absolute;
  bottom: 5px;
  right: 0;
  text-align: justify;
  z-index: 99999;
}

.showView {
  display: none;
}

.KPIEnergyWidgets {
  padding-top: 65px;
  padding-bottom: 40px;
  padding-left: 54px;
  padding-right: 40px;
}

.energy-meter-heading {
  font-family: "Roboto Flex", sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 21.6px;
  letter-spacing: 0em;
  text-align: left;
  width: 186.9px;
  height: 13px;
  top: 39px;
  left: 30px;
  align-self: flex-start;
  font-variation-settings: "wdth"128, "GRAD"-66, "slnt"0, "XTRA"468,
    "XOPQ"96, "YOPQ"79, "YTLC"514, "YTUC"712, "YTAS"750, "YTDE"-203,
    "YTFI"738;
}

.row .column {
  width: 30%;
}

.changeSiteModeHeader {
  color: var(--Light-Surface-color-Grey-900, #272729);
  text-align: center;
  font-family: "Roboto Flex";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
}

.changeSiteModeTitle {
  color: var(--Light-Surface-color-Grey-900, #272729);
  font-family: "Roboto Flex";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  margin-bottom: 18px;
}

.MuiPaper-rounded {
  border-radius: 20px;
}

.siteModbusInput {
  display: flex;
  width: 350px;
  height: 56px;
  padding: 0px 16px;
  align-items: center;
  border-radius: 8px;
  border: 1px solid var(--Light-Surface-color-Grey-400, #c3c3c5);
  background: var(--Light-Surface-color-Grey-50, #fff);
}

.filter-drop-down-sitemode {
  display: flex;
  width: 100%;
  height: 56px;
  padding: 0px 16px;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  border: 1px solid var(--Light-Secondary-Blue-300, #9cc4f2);
  background: var(--Light-Surface-color-Grey-50, #fff);
  box-shadow: 0px 0px 4px 0px rgba(119, 177, 237, 0.81);
}

.dropdownSiteModeOptionStyle {
  display: flex;
  width: 400px;
  height: 56px;
  padding: 0px 16px;
  align-self: center;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  border: 1px solid var(--Light-Secondary-Blue-300, #9cc4f2);
  background: var(--Light-Surface-color-Grey-50, #fff);
  box-shadow: 0px 0px 4px 0px rgba(119, 177, 237, 0.81);
  margin-top: 5px;
  color: var(--Light-Surface-color-Grey-900, #272729);
  text-align: justify;
}

.optionValue {
  padding: 0.65rem 1.25rem !important;
  height: 40px;
}

.dropdownSiteStyle {
  width: 400px;
}

select option {
  height: 56px;
}

.MuiDialog-paperWidthSm {
  max-width: 463px;
  border-radius: 20px;
  background: var(--Light-Surface-color-Grey-50, #fff);
  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.07);
}

.buttonSiteModeView {
  display: flex;
  /* margin-top: 15px; */
  margin: auto;
  justify-content: space-evenly;
  width: 85%;
}

.tablewidth {
  width: 442px;
  height: 423px;
  opacity: 0px;
  margin: 40px;
  background: var(--Light-Surface-color-Grey-50, #fff);
  margin: 40px;
}

/* .lastSeen {
  margin-right: 40px;
} */

.scrollContentEnergy {
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  margin-left: 9px;
}

.chartHeading {
  font-size: 16px;
  font-weight: 700;
  line-height: 21.6px;
  letter-spacing: 0em;
  text-align: left;
  width: 186.9px;
  height: 13px;
  top: 39px;
  left: 30px;
  align-self: flex-start;
  margin-bottom: 20px;
  font-family: "Roboto Flex", sans-serif;
  margin-left: 10px;
  margin-top: 10px;
}

.tableHeading {
  font-size: 16px;
  font-weight: 600;
  width: 100%;
  text-align: left;
  margin-bottom: 10px;
  font-family: "Roboto Flex", sans-serif;
  border-bottom: 1px solid #ddd;
}

.tableDataText {
  font-size: 16px;
  font-weight: 125;
  width: 100%;
  text-align: left;
  font-family: "Roboto Flex", sans-serif;
}

.kpiAlign {
  margin-left: 0px;
}

.chartSetWidthGrid {
  width: 40%;
  display: flex;
  height: 334px;
  padding: var(--24, 24px);
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  background: var(--Light-Surface-color-Grey-50, #fff);
  margin-left: 40px;
}

/* 
.chartSetWidthParameter {
  display: flex;
  height: 334px;
  padding: var(--24, 24px);
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  background: var(--Light-Surface-color-Grey-50, #fff);
  margin-left: 30px;
} */

.mobile-kpi-widgets {
  display: none;
}

@media only screen and (max-width: 768px) {
  .KPIEnergyWidgets {
    display: none;
  }

  .mobile-kpi-widgets {
    display: block;
  }

  .kpiView {
    margin-left: 0;
  }

  .top-chart {
    margin: 0 10px 10px 20px;
    padding: 10px;
    box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.07);
  }

  .dropdownSiteStyle {
    /* margin: 0px 14px !important; */
    width: 100% !important;
  }

  .chartSetWidthParameter {
    margin-left: 0px !important;
    padding: 10px !important;
    box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.07);
  }

  .kpi-card {
    border-radius: 8px;
    width: 90%;
    margin: auto;
    background: var(--Light-Surface-color-Grey-50, #fff);
    box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.07);
    padding: 20px;
  }

  .table.roboto-flex-uniquifier {
    border-collapse: collapse;
    margin: 0 auto;
  }

  .table.roboto-flex-uniquifier td {
    padding: 0px;
    border: none;
  }

  .table.roboto-flex-uniquifier td:first-child {
    padding-right: 50px;
  }

  .fullwidth {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }

  .buttonSiteModeView {
    width: 100%;
  }

  .MuiDialog-paperWidthSm {
    width: 98%;
  }
}