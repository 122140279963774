.navbar-project-name {
  font: normal normal 300 12px/18px Montserrat;
  letter-spacing: 1.2px;
  color: #e3e3e3;
  opacity: 1;
  padding-left: 20px;
  margin-bottom: 0;
}

.leftMenucontainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.navbar-project-subname {
  font: normal normal bold 20px/36px Montserrat;
  letter-spacing: 0.98px;
  color: #e3e3e3;
  padding-left: 20px;
  padding-top: 16px;
  opacity: 1;
}

.main-menu {
  width: 53px;
  background: var(--Light-Surface-color-Grey-50, #fff);
  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.07);
  height: 1322px;
}

.projectButtonSwitch {
  background-color: #2c42b8;
  border: 0px;
  display: flex;
}

.main-menu-container {
  /* position: relative; */
  /*  padding-top: 4.6px; */
  /* height: 95vh; */
  margin-left: 10px;
}

.iconBlack {
  color: #000000;
}

.iconWhite {
  color: #fff;
}

.top-menu {
  margin-left: 224px;
  padding-top: 18px;
  padding-right: 41px;
  /* background-color: #ededed; */
  width: calc(100% - 224px);
}

.buildName {
  display: flex;
  margin-left: 22px;
  font-size: 14px;
  font-weight: 400;
  font-family: "Roboto Flex";
}

.buildUserName {
  display: flex;
  margin-left: 22px;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 400;
  font-family: "Roboto Flex";
}

.versionName {
  margin-left: 3px;
  margin-right: 2px;
}

.versionViewName {
  color: var(--Light-Surface-color-Grey-900, #272729);
  /* Body/Body 3 */
  font-family: "Roboto Flex";
  font-size: 14px;
  font-style: normal;
  font-weight: 130;
  line-height: 120%;
  /* 16.8px */
}

.top-menu-container {
  right: 0;
}

.sticky-submenu {
  position: -webkit-sticky;
  /* Safari */
  position: sticky;
  top: 0;
  background-color: #ededed;
}

.icon-grey {
  color: #e3e3e3;
}

.user-dialog {
  padding: 1rem 0 1rem 0;
  position: absolute;
  z-index: 1100;
  top: 70px;
  right: 40px;
  max-width: 80%;
  min-height: 73px;
  background: #fefefe 0% 0% no-repeat padding-box;
  box-shadow: 3px 6px 3px #00000026;
  opacity: 1;
}

.user-dialog:before {
  content: "";
  position: absolute;
  border-style: solid;
  border-width: 10px 12px;
  border-color: transparent transparent #fefefe transparent;
  right: 8px;
  /* TOP IS DOUBLE THE HEIGHT OF THE BORDER */
  top: -20px;
}

.user-name {
  text-align: left;
  font: normal normal normal 14px/18px Montserrat;
  letter-spacing: 0px;
  color: #1a1a1a;
  opacity: 0.87;
  padding-left: 23.5px;
  padding-right: 18px;
  padding-bottom: 7px;
}

.logout-btn {
  background: none;
  border: none;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  text-align: left;
  font: normal normal bold 14px/18px Roboto Flex;
  letter-spacing: 0px;
  color: #555555;
  opacity: 0.87;
}

.main-menu-logo {
  position: absolute;
  bottom: 0;
  width: 150px;
  /* margin-left: 50px; 
  margin-bottom: 30px; */
}

.left-menu-link {
  font: normal normal normal 14px/20px Montserrat;
  letter-spacing: 0.7px;
  color: #e3e3e3 !important;
  padding: 16px 0 16px 26px;
}

.left-menu-link:hover {
  text-decoration: none;
  color: #000066 !important;
}

.left-menu-link-active {
  text-align: left;
  font: normal normal bold 15px/18px Montserrat;
  letter-spacing: 0.7px;
  color: #000066;
  /*  padding: 16px 0; */
}

.left-menu-link-active:hover {
  color: #000066;
}

.left-menu-link-icon {
  padding-left: 24px;
  margin-right: 21px;
  height: 41px;
  display: inline-block;
  vertical-align: middle;
}

.left-menu-link-icon:hover {
  text-decoration: none;
  color: #000066;
}

.active-left-menu-link-icon {
  height: 38px;
  padding-left: 19px;
  border-left: solid 5px #000066;
  padding-top: 8px;
}

.no-underscore {
  text-decoration: none;
}

.gatewayColor {
  color: var(--Ocean, #006);
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  margin-left: 35px;
}

.basepannelheading {
  color: var(--Ocean, #006);
  font-family: Montserrat;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.64px;
}

.subMenuHeading {
  color: var(--Body, #313131);
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.projectShadow {
  display: flex;
  padding: 6px 9px;
  align-items: flex-start;
  gap: 8px;
  border-radius: 8px;
  border: 2px solid var(--Light-Primary-Green-600, #25dba7);
  background: var(--Light-Primary-Green-600, #25dba7);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
}

.projectShadowTextColor {
  color: var(--Light, #fff);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  text-align: left;
}

.inactive-left-menu-link-icon-shadow {
  display: flex;
  padding: 20px 16px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
}

.active-left-menu-link-icon-shadow {
  display: flex;
  padding: 20px 16px;
  flex-direction: column;
  align-items: flex-start;
  background-color: #f6f6f8;
  border-left: 2px solid #4ce1b6;
  /* width: 180px; */
}

.leftMenucontainer div .over:hover {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #f6f6f8;
  border-left: 2px solid #4ce1b6;
  width: 180px;
}

.active-left-menu-link-icon-shadow-map-mouse-hover {
  height: 65px;
  color: white;
  background: #4ce1b6;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  padding: 20px 16px;
  font-family: "Roboto Flex", sans-serif;
}

.active-left-menu-link-icon-shadow-map {
  display: flex;
  padding: 20px 16px;
  background-color: #4ce1b6;
  flex-direction: column;
  align-items: flex-start;
  color: white;
}

.menuleftcolor {
  color: var(--Light-Surface-color-Grey-900, #272729);
  font-family: Linearicons-Free;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.projectIcon {
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  color: white;
  align-self: center;
}

.top-menu-container-new {
  display: inline-flex;
  padding: 28px 0px 29px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 28px;
}

.project-container-frame {
  display: inline-flex;
  padding: 28px 0px 29px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 28px;
}

.project-container-name {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

/* .menuscroll {
  height: 200px;
  overflow-x: hidden;
  overflow-y: scroll;
} */
.active {
  margin-left: 10px;
  display: inline !important;
  width: 180px;
  text-align: center;
}

.activesiteMap {
  position: relative;
  display: block;
  display: flex;
  align-items: center;
  height: 65px;
  width: 130px;
  background: #4ce1b6;
  margin-left: 35px;
  bottom: 44px;
  word-wrap: break-word;
  padding: 8.5px;
}

.inactive {
  display: none !important;
}

.bottom-navigation {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  border-top: 1px solid #ccc;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
}

.bottom-nav-link {
  text-decoration: none;
  color: black;
  padding: 5px;
}

.bottom-nav-icon {
  font-size: 24px;
}

.bottom-nav-link.bottom-active .bottom-nav-icon {
  color: #4ce1b6;
}

.bottom-active {
  display: inline !important;
}

/* Notification styles */
.settings-container {
  border-radius: 6px;
  width: 90%;
  margin: auto;
  background: var(--Light-Surface-color-Grey-50, #fff);
  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.07);
  padding: 30px;
  max-width: 900px;
  margin: 0 auto;
  color: #272729;
}

/* Title */
.settings-title {
  font-size: 18px;
  text-align: left;
  font-family: "Roboto Flex";
  font-weight: 700;
  line-height: 21.6px;
  color: #272729;
}

.settings-span {
  text-align: left;
  font-family: "Roboto Flex";
  font-weight: 125;
}

/* Responsive Table */
.responsive-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.form-label {
  text-align: left;
  font-family: "Roboto Flex";
  font-weight: 125;
  font-size: 16px;
  line-height: 19.2px;
}

.responsive-table th,
.responsive-table td {
  text-align: center;
}

.cat-width {
  width: 6.5rem;
  margin-left: 40px;
}

.responsive-table th {
  text-align: center;
  color: #272729;
  font-family: "Roboto Flex";
  font-weight: 600;
  font-size: 16px;
}

.responsive-table tr {
  font-weight: bold;
  text-align: center;
  color: #272729;
  font-family: "Roboto Flex";
  font-weight: 125;
  font-size: 16px;
}

.responsive-table body {
  width: 50% !important;
}

/* SMS Section */
.sms-section {
  margin-bottom: 20px;
}

.word-wrap {
  word-wrap: break-word;

}

.sms-inputs {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.sms-inputs .form-group {
  flex: 1;
  min-width: 150px;
}

.sms-inputs input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Save Button */
.save-btn-container {
  text-align: right;
}

.save-btn {
  background-color: #25DBA7;
  color: white;
  padding: 6px 30px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.save-btn:hover {
  background-color: #088d67;
}

.custom-checkbox {
  width: 20px;
  height: 20px;
  appearance: none;
  border: 2px solid #25DBA7;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  background-color: white;
  display: inline-block;
  vertical-align: middle;
  transition: background-color 0.2s ease, border-color 0.2s ease;
}

.custom-checkbox:checked {
  border-color: #25DBA7;
}

.custom-checkbox:checked::after {
  content: "✔";
  color: #25DBA7;
  font-size: 14px;
  display: block;
  text-align: center;
  line-height: 18px;
}

.sms-margin-top {
  margin-top: 2.5rem;
  display: flex;
  flex-direction: column;
}

.alertDialogNotification {
  width: 336px;
  position: absolute;
  bottom: 5px;
  right: 0;
  text-align: justify;
  z-index: 99999;
}

/* Responsive Design */
@media (max-width: 768px) {
  .user-dialog {
    top: 70px;
    max-width: 80%;
    min-width: 177px;
  }

  .success-dialog {
    background-color: #84ce66;
    color: #272729;
    margin-right: 0;
  }

  .alertDialogNotification {
    position: fixed;
    bottom: 50%;
    left: 50%;
    transform: translate(-50%, 50%);
    width: 100%;
    margin: auto;
    max-width: 336px;
  }

  .notification-section {
    display: none;
  }

  .notification-section-mobile {
    display: block;
  }

  .responsive-table th,
  .responsive-table td {
    font-size: 14px;
    padding: 8px;
  }

  .sms-inputs {
    flex-direction: column;
    width: 80%;
  }

  .cat-width {
    margin-left: 0px;
  }



}


@media (max-width: 480px) {
  .settings-title {
    font-size: 16px;
    font-weight: 550;
    line-height: 19.2px;
  }

  .sms-card {
    border-radius: 6px;
    /* margin: auto; */
    background: var(--Light-Surface-color-Grey-50, #fff);
    box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.12);
    padding: 12px;
    margin-top: 15px;
    color: #272729;
  }

  .success-dialog {
    background-color: #84ce66;
    color: #272729;
    margin-right: 0;
  }


  .sms-margin-top {
    margin-top: 0;
  }

  .notification-section {
    display: none;
  }

  .settings-container {
    padding: 15px;
    box-shadow: none;
    border-radius: 0;
    width: 100%;
    background: none;
  }

  .notification-section-mobile {
    border-radius: 6px;
    margin: auto;
    background: var(--Light-Surface-color-Grey-50, #fff);
    box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.12);
    padding: 12px;
    margin-top: 15px;
    color: #272729;
  }

  .responsive-table th,
  .responsive-table td {
    font-size: 12px;
    padding: 5px;
  }

  .save-btn {
    font-size: 14px;
    padding: 8px 15px;
  }

  .notification-options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
  }
}

@media (min-width: 769px) {
  .notification-section-mobile {
    display: none;
  }

  .success-dialog {
    background-color: #84ce66;
    margin-right: 20px;
    color: #272729;
  }

}