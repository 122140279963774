.margin-mob {
  margin-top: 100px;
  width: 100%;
}

@media (max-width: 480px) {
  .margin-mob {
    margin-top: 60px;
    background-color: #FFFFFF;
  }
}