.kpiView {
  border-radius: 8px;
  background: var(--Light-Surface-color-Grey-50, #fff);
  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.07);
  display: flex;
  align-items: flex-end;
  margin-left: 15px;
  padding: 20px;
}

.columnView {
  display: contents;
}

.noProject {
  text-align: center;
  padding: 15px;
}

.KPIWidgets {
  /* padding: 35px; */
  padding-top: 106px;
  padding-bottom: 88px;
  /* padding-left: 140px;
  padding-right: 154px; */
  width: 80%;
  margin: auto;
}

.SitesWidgets {
  /* height: 335px; */
  /* padding-bottom: 45px; */
  /* padding-left: 140px;
  padding-right: 154px; */
  width: 80%;
  margin: auto;
}

.mobileWidgets {
  display: none;
}

@media only screen and (max-width: 768px) {
  .KPIWidgets {
    display: none;
  }
  .scroll-topbar {
    padding-top: 70px;
  }
  .scroll-container {
    height: calc(100vh - 100px);
    overflow-y: scroll;
  }
  /* .scroll-container::-webkit-scrollbar {
    width: 12px;
  }
  .scroll-container::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  .scroll-container::-webkit-scrollbar-thumb {
    background: #7b7b7d;
    border-radius: 6px;
  } */
  .mobileWidgets {
    padding-bottom: 16px;
    width: 90%;
    margin: auto;
    display: block;
  }
  .kpiWidgets {
    border-radius: 8px;
    background: var(--Light-Surface-color-Grey-50, #fff);
    box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.07);
    padding: 20px;
  }
}

.SitesMobileWidgets {
  display: none;
}

@media only screen and (max-width: 768px) {
  .SitesWidgets {
    display: none;
  }
  .SitesMobileWidgets {
    padding-left: 3%;
    padding-right: 3%;
    margin: auto;
    display: block;
  }
  .table-mobile-widgets {
    padding-bottom: 0px;
  }
  .kpiMobileWidgets {
    width: 100%;
    padding: 20px;
    flex-shrink: 0;
    border-radius: 8px;
    background: var(--Light-Surface-color-Grey-50, #fff);
  }
  .alert-last-seen {
    padding-left: 10px;
  }
}

.kpiTextAlign {
  text-align: center;
}

.siteSetBackground {
  background: var(--Light-Surface-color-Grey-100, #fbfbfe);
}

.sitesHeader {
  color: var(--Light-Surface-color-Grey-900, #272729);
  padding: 22px;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

.valueTotal {
  margin-left: 40px;
  color: var(--Light-Surface-color-Grey-900, #272729);
  font-family: "Roboto Flex";
  font-size: 16px;
  font-style: normal;
  font-weight: 125;
  line-height: 120%;
  float: left;
}

.siteScroll {
  overflow-y: scroll !important;
  max-height: 250px !important;
  overflow-x: hidden !important;
}

.wattMaestro-lg {
  width: 100%;
  max-width: 1440px;
  margin-right: auto;
  /* padding-left: 10px; */
  /* padding: 0px 0px 0px 50px; */
}

.table > :not(:last-child) > :last-child > * .tableHeader {
  border-bottom-color: #272729;
}

.firstHeader {
  width: "5%";
}

.alertIcon {
  color: #ef6f00;
}

.successIcon {
  color: #2f8301;
}

.cancelShowIcon {
  color: #880000;
}

.divUserPopup {
  margin-left: 20px;
  margin-top: 12px;
  width: 80%;
}

.buildUserWattName {
  display: flex;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 400;
  font-family: "Roboto Flex";
}
.emailHR {
  border: 1px solid black;
  margin: 5px;
}

.versionUserViewName {
  color: var(--Light-Surface-color-Grey-900, #272729);
  cursor: pointer;
  font-family: "Roboto Flex";
  font-size: 16px;
  font-style: normal;
  font-weight: 130;
  line-height: 120%;
  margin-bottom: 10px;
  display: flex;
  /* 16.8px */
}

.versionEmailViewName {
  color: var(--Light-Surface-color-Grey-900, #272729);
  /* Body/Body 3 */
  font-family: "Roboto Flex";
  font-size: 16px;
  font-style: normal;
  font-weight: 130;
  line-height: 120%;
  display: flex;
  /* 16.8px */
}
.iconView {
  font-size: 20px;
  color: #555555;
}

.functionIcon {
  margin-left: 10px;
}
.userManual {
  margin-left: 12px;
}
.user-watt-dialog {
  padding: 1rem 0 1rem 0;
  top: 70px;
  right: 40px;
  /* max-width: 80%; */
  min-height: 73px;
  background: #fefefe 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 3px 3px #00000026;
  opacity: 1;
  width: 250px;
  height: 170px;
}

.user-name-watt {
  letter-spacing: 0px;
  color: #555555;
  opacity: 0.87;
  margin-right: 10px;
}

.angleDown {
  color: #1a1a1a;
  margin-top: 0px;
}

.btnwatt {
  border: none;
  background-color: #fefefe;
}

.loginImgDiv {
  width: 100%;
  box-sizing: border-box;
}

.font-style-without-title {
  font-family: "Roboto Flex";
  color: #555555;
}

.font-style {
  font-family: "Roboto Flex";
}

.margin-bottom {
  margin-bottom: 0px;
}

.siteOverview-padding {
  padding-left: 85px;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-right: 70px;
}

.paading-site-upper-widigt {
  padding-right: 10px;
}

.roboto-flex-uniquifier-user {
  font-family: "Roboto Flex", sans-serif;
  font-optical-sizing: auto;
  font-size: 14px;
  font-weight: 130;
  font-style: normal;
}

.roboto-flex-uniquifier {
  font-family: "Roboto Flex", sans-serif;
  font-optical-sizing: auto;
  font-weight: 120;
  font-style: normal;
}

.roboto-flex-uniquifier-title {
  font-family: "Roboto Flex", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}

/* .table-container {
  width: 100%;
  overflow-x: auto;
  margin-bottom: 10px;
} */

.table-container::-webkit-scrollbar {
  width: 8px;
}

.table-header {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
}

.table-container {
  max-height: 70vh;
  overflow-y: auto;
}

/* For smaller screens */
@media screen and (max-width: 1600px) {
  .table-container {
    max-height: 300px;
    overflow-y: auto;
  }
}

/* For larger screens */
@media screen and (min-width: 1601px) {
  .table-container {
    max-height: 58vh;
    overflow-y: auto;
  }
}

@media screen and (max-width: 500px) {
  .sitesOverview {
    flex-shrink: 0;
    border-radius: 8px;
    background: var(--Light-Surface-color-Grey-50, #fff);
  }
}
