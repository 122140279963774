.siteInfoMember {
  max-width: 1088px;
  /* height: 345px; */
  width: 100%;
  flex-shrink: 0;
  background-color: var(--Light-Surface-color-Grey-50, #fff);
  padding: 30px 30px 20px 30px;
  border-radius: 6px;
}

.viewMember {
  display: inline;
}

.addMember {
  display: flex;
  width: 135px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: var(--Light-Primary-Green-600, #25dba7);
  color: var(--Light-Surface-color-Grey-100, #fbfbfe);
  border: var(--Light-Surface-color-Grey-100, #fbfbfe);
  font-family: "Roboto Flex";
  font-size: 13px;
  font-style: normal;
  font-weight: 800;
  line-height: 120%;
  /* 15.6px */
  letter-spacing: 0.26px;
  text-transform: uppercase;
  float: right;
}

.memberIcon {
  margin-left: 5px;
}

.memberText {
  color: var(--Light-Surface-color-Grey-900, #272729);
  font-family: "Roboto Flex";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

.rowDesign {
  border-bottom: 1px solid var(--Light-Surface-color-Grey-400, #c3c3c5);
  margin-bottom: 30px;
}

.memberTextRow {
  color: var(--Light-Surface-color-Grey-900, #272729);
  margin-bottom: 15px;
  border-bottom: 1px solid var(--Light-Surface-color-Grey-400, #c3c3c5);
  font-family: "Roboto Flex";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

.memberViewAlign {
  margin-top: 40px;
  text-align: center;
}

.remove {
  display: flex;
  width: 100px;
  height: 36px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  background: var(--Light-Surface-color-Grey-500, #a5a5a7);
  color: var(--Light-Surface-color-Grey-100, #fbfbfe);
  border: var(--Light-Surface-color-Grey-100, #fbfbfe);
  margin-left: 30px;
}

.justifyCol {
  text-align: justify;
}

.alignItem {
  text-align: -webkit-center;
}

.accessType {
  display: flex;
  width: 190px;
  height: 36px;
  padding: 0px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid var(--Light-Surface-color-Grey-400, #c3c3c5);
  background: var(--Light-Surface-color-Grey-50, #fff);
}

.infoProjectIcon {
  margin-right: 7px;
  font-weight: 600;
  color: var(--Light-Surface-color-Grey-50, #fff);
}

.notRemoveOption {
  color: #ba1313;
  font-size: 20px;
  margin-right: 7px;
  font-weight: 600;
}

.removeText {
  color: var(--Light-Surface-color-Grey-50, #fff);
  text-align: center;
  /* Button/Button */
  font-family: "Roboto Flex";
  font-size: 13px;
  font-style: normal;
  font-weight: 800;
  line-height: 120%;
  /* 15.6px */
  letter-spacing: 0.26px;
  text-transform: uppercase;
}

.select-container {
  position: relative;
  border-radius: 8px;
  margin-bottom: 15px;
  width: 95%;
  display: block;
  width: 100%;
  padding: 8px 20px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
}

.dropdownUserMemberStyle {
  /* display: flex; */
  width: 190px;
  font-weight: 100;
  padding: 0px 16px;
  margin-bottom: 15px;
  align-self: center;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  border: 1px solid var(--Light-Secondary-Blue-300, #9cc4f2);
  background: var(--Light-Surface-color-Grey-50, #fff);
  box-shadow: 0px 0px 4px 0px rgba(119, 177, 237, 0.81);
  margin-top: 8px;
  color: var(--Light-Surface-color-Grey-900, #272729);
  text-align: justify;
}

.disabledOption {
  background-color: #e8e8e8;
  border: 0px;
}

.removeTextDialog {
  color: var(--Light-Surface-color-Grey-900, #272729);
  /* Body/Body 2 */
  font-family: "Roboto Flex";
  font-size: 16px;
  font-style: normal;
  font-weight: 125;
  line-height: 120%;
  /* 19.2px */
}

.colAlign {
  margin-top: 6px;
  font-weight: 100;
}

.alignHeader {
  text-align: justify;
}

select option {
  padding: 5px;
  color: #fff;
  text-shadow: 0 1px 0 var(--Light-Surface-color-Grey-500, #a5a5a7);
  color: var(--Light-Surface-color-Grey-900, #272729);
  /* background: var(--Light-Primary-Green-600, #25dba7); */
}

.memberAlign {
  text-align: center;
}

option:hover {
  background-color: var(--Light-Surface-color-Grey-500, #a5a5a7);
}

.addText {
  color: var(--Light-Surface-color-Grey-900, #272729);
  align-self: stretch;
  font-family: "Roboto Flex";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  margin-bottom: 10px;
}

.addMemberText {
  display: flex;
  width: 100%;
  height: 56px;
  padding: 0px 16px;
  align-items: center;
  border-radius: 8px;
  border: 1px solid var(--Light-Surface-color-Grey-400, #c3c3c5);
  background: var(--Light-Surface-color-Grey-50, #fff);
  margin-bottom: 15px;
}

.changeDialogModeHeader {
  color: var(--Light-Surface-color-Grey-900, #272729);
  text-align: center;
  font-family: "Roboto Flex", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  border-radius: 50px;
}

.context-text {
  font-family: "Roboto Flex", sans-serif;
  font-size: 16px;
  font-weight: 125;
  line-height: 19.2px;
  text-align: left;
  width: 254px;
  height: 38px;
  gap: 0px;
  opacity: 0;
}

.userMemberData {
  overflow-x: hidden;
  max-height: 200px;
  overflow-y: auto;
  scrollbar-color: var(--scroll-bar-color) var(--scroll-bar-bg-color);
  scrollbar-width: thin;
}

/* For smaller screens */
@media screen and (max-width: 1600px) {
  .userMemberData {
    max-height: 200px;
    overflow-y: auto;
  }
}

/* For larger screens */
@media screen and (min-width: 1601px) {
  .userMemberData {
    max-height: 45vh;
    overflow-y: auto;
  }
}

.headerAccess {
  text-align: center;
  font-family: "Roboto Flex", sans-serif;
  font-size: 24px;
  font-weight: 600;
}

.alertUserDialog {
  width: 336px;
  position: absolute;
  bottom: 5px;
  right: 0;
  text-align: justify;
  z-index: 99999;
}

.changeSiteModeUserHeader {
  color: var(--Light-Surface-color-Grey-900, #272729);
  text-align: center;
  /* Desktop/Heading 3 */
  font-family: "Roboto Flex";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 41.6px */
}

.dropdownAddMemberOptionStyle {
  width: 100%;
}

.dropdownAccessOptionStyle {
  width: 190px;
}

.addMemberoptionValue {
  padding: 0.65rem 1.25rem !important;
  height: 35px;
}

.mobile-info-member {
  display: none;
}

@media only screen and (max-width: 768px) {
  .siteInfoMember {
    display: none;
  }

  .mobile-info-member {
    display: block;
  }

  .addMember {
    width: 40px;
  }

  .mobile-view-member {
    padding: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.105),
      0 6px 20px 0 rgba(0, 0, 0, 0.105);
    background-color: var(--Light-Surface-color-Grey-50, #fff);
    border-radius: 8px;
  }

  .mobile-tex {
    font-size: 18px;
  }

  .mobile-text-email {
    font-size: 16px;
    font-weight: 100;
  }

  .MobiledropdownUserMemberStyle {
    width: 140px;
    height: 30px;
    padding: 0px 16px;
    align-self: center;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    border: 1px solid var(--Light-Surface-color-Grey-500, #a5a5a7);
    background: var(--Light-Surface-color-Grey-50, #fff);
    margin-top: 10px;
    font-weight: 100;
  }

  .MoblileaccessType {
    display: flex;
  }

  .removeMobile {
    width: 40px;
    height: 40px;
    border-radius: 8px;
    background: var(--Light-Surface-color-Grey-500, #a5a5a7);
    border: var(--Light-Surface-color-Grey-100, #fbfbfe);
  }

  .ProjectIconMobile {
    font-weight: 600;
    color: var(--Light-Surface-color-Grey-50, #fff);
  }

  .faCheckView {
    float: right;
    color: #1a6bc7;
  }

  .MuiPaper-rounded {
    border-radius: 20px !important;
  }

  .MuiDialog-paper {
    margin: 20px !important;
  }
}
