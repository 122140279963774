.notification-watt-dialog {
  padding: 1rem 0 1rem 0;
  top: 70px;
  right: 40px;
  /* max-width: 80%; */
  min-height: 73px;
  background: #fefefe 0% 0% no-repeat padding-box;
  box-shadow: 3px 6px 3px #00000026;
  opacity: 1;
  width: 320px;
  height: 400px;
  overflow-y: scroll;
  max-height: 400px;
  font-family: "Roboto Flex", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

.unreadNotification {
  color: white;
  border: 2px solid red;
  border-radius: 6px;
  font-family: "Roboto Flex";
  background-color: red;
  text-align: center;
  height: 28px;
  font-size: 16px;
  font-weight: 400;
  width: 40px;
}

.NotificationHeader {
  font-weight: 600;
  font-family: "Roboto Flex", sans-serif;
  font-size: 18px;
  font-optical-sizing: auto;
  font-style: normal;
  margin-left: 12px;
  margin-bottom: 15px;
}

.markasAllRead {
  font-family: "Roboto Flex", sans-serif;
  background: #25dba7;
  width: 133px;
  height: 40px;
  border-radius: 8px;
  color: var(--Light-Surface-color-Grey-50, #fff);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: none;
  float: right;
  margin-right: 14px;
}

.notificationBar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 2px;
}

.alignRow {
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.notification-title {
  font-family: "Roboto Flex";
  font-weight: 550;
  font-size: 16px;
}

.notification-subtitle-bottom {
  font-family: "Roboto Flex";
  font-weight: 130;
  margin-top: 10px;
  margin-bottom: 0px;
  font-size: 14px;
}
.notification-subtitle-top {
  font-family: "Roboto Flex";
  font-weight: 130;
  margin-top: 0px;
  margin-bottom: 10px;
  font-size: 14px;
}

.notificationLink {
  font-weight: 500;
  color: #25dba7;
}
