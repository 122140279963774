.formInputEmail {
  border-radius: 6px;
  border: 1px solid gray;
  height: 38px;
  width: 85%;
}

.emailBox {
  text-align: center;
}

.emailValidation {
  margin-left: 50px;
}

.successView {
  text-align: center;
}
.failView {
  text-align: center;
  color: red;
}

.register-signUp-btn-watt {
  width: 100%;
  height: 38px;
  flex-shrink: 0;
  background: var(--Light-Primary-Green-500, #4ce1b6);
  text-decoration: none;
  border-radius: 5px;
  border: none;
}

.register-Text {
  color: var(--Light-Surface-color-Grey-900, #272729);
font-family: "Roboto Flex",sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 130;
line-height: 120%;
}

.email-Text {
  width: 86px;
  color: var(--Light-Surface-color-Grey-900, #272729);
  Line-height: normal;
  font-size: 12px;
  letter-spacing: 0.26px;
  text-transform: math-auto;
  font-family: "Roboto Flex", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600; 
  font-style: normal;

}

.login-register-Watt {
  color: var(--Light-Primary-Green-500, #4ce1b6);
  text-align: center;
  
  margin-left: 11px;
  font-family: "Roboto Flex", sans-serif;
  font-size: 15px;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  line-height: 120%;
  font-variation-settings:
    "slnt" 0,
    "wdth" 100,
    "GRAD" 0,
    "XTRA" 468,
    "YOPQ" 79,
    "YTAS" 750,
    "YTDE" -203,
    "YTFI" 738,
    "YTLC" 514,
    "YTUC" 712;
}
.alreadyMsg {
  font-family: "Roboto Flex", sans-serif;
  font-size: 13px;
  font-optical-sizing: auto;
  font-weight: 130;
  font-style: normal;
  line-height: 120%;
  color: var(--Light-Surface-color-Grey-900, #272729);

}

.link-Div {
  text-align: center;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 225px;
  height: auto;
  margin: 0 auto;
  padding: 10px;
  position: relative;
  display: flex;
}
