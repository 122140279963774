.KPISolarWidgets {
  padding-top: 70px;
  padding-bottom: 40px;
  padding-left: 54px;
  padding-right: 40px;
}

.kpiSolarView {
  border-radius: 8px;
  background: var(--Light-Surface-color-Grey-50, #fff);
  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.07);
  display: flex;
  align-items: flex-end;
  padding: 20px;
}

.tablewidth {
  width: 442px;
  height: 423px;
  opacity: 0px;
  margin: 40px;
  background: var(--Light-Surface-color-Grey-50, #fff);
  margin: 40px;
}

/* .lastSeen {
  margin-right: 40px;
} */

.scrollContentSolar {
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  margin-left: 9px;
}

.chartHeading {
  font-size: 18px;
  font-weight: 700;
  line-height: 21.6px;
  letter-spacing: 0em;
  text-align: left;
  width: 186.9px;
  height: 13px;
  top: 39px;
  left: 30px;
  align-self: flex-start;
  margin-bottom: 20px;
  font-family: "Roboto Flex", sans-serif;
  margin-left: 10px;
  margin-top: 10px;
}

.tableHeading {
  font-size: 16px;
  font-weight: 600;
  width: 100%;
  text-align: left;
  margin-bottom: 10px;
  font-family: "Roboto Flex", sans-serif;
  border-bottom: 1px solid #ddd;
}

.tableDataText {
  font-size: 16px;
  font-weight: 125;
  width: 100%;
  text-align: left;
  font-family: "Roboto Flex", sans-serif;
}

.chartSetWidthGrid {
  width: 40%;
  display: flex;
  height: 334px;
  padding: var(--24, 24px);
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  background: var(--Light-Surface-color-Grey-50, #fff);
  margin-left: 40px;
}

.chartSetWidthParameter {
  display: flex;
  height: 334px;
  padding: var(--24, 24px);
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  background: var(--Light-Surface-color-Grey-50, #fff);
  margin-left: 30px;
}

.container-top-align {
  display: flex;
  width: 100%;
  margin: auto;
  justify-content: center;
  flex-direction: row;
}

@media only screen and (max-width: 1000px) {
  .container-top-align {
    flex-direction: column;
    width: 100%;
  }

  /* .top-chart {
    width: 100%;
  } */
}

.siteSolarIcon {
  margin-left: 1px;
}

.siteSolarIcon:hover {
  background: var(--Light-Surface-color-Grey-500, #fbfbfe);
}

.alertSolarDialog {
  width: 310px;
  position: absolute;
  bottom: 5px;
  right: 0;
  text-align: justify;
  z-index: 99999;
}

.alertCurtailmentErrorSolarDialog {
  width: 410px;
  position: absolute;
  bottom: 5px;
  right: 0;
  text-align: justify;
  z-index: 99999;
}

.changeSiteModeSolarHeader {
  color: var(--Light-Surface-color-Grey-900, #272729);
  text-align: center;
  /* Desktop/Heading 3 */
  font-family: "Roboto Flex";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  /* 41.6px */
}

.dropdownSiteModeOptionStyle {
  display: flex;
  width: 400px;
  height: 56px;
  padding: 0px 16px;
  align-self: center;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  border: 1px solid var(--Light-Secondary-Blue-300, #9cc4f2);
  background: var(--Light-Surface-color-Grey-50, #fff);
  box-shadow: 0px 0px 4px 0px rgba(119, 177, 237, 0.81);
  margin-top: 5px;
  color: var(--Light-Surface-color-Grey-900, #272729);
  text-align: justify;
}

.optionValue {
  padding: 0.65rem 1.25rem !important;
  height: 40px;
}

.dropdownSiteStyle {
  width: 400px;
}

@media only screen and (max-width: 768px) {
  .KPISolarWidgets {
    display: none;
  }
}